import { getBulletinList, getMessageList, readMessageById } from '@/apis'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

// 业务类型
const BUSINESS_ENUM = {
  SYS_NOTIFY: '4', // 系统公告
  RESIDENT: '3', // 住户管理
  BILL_PAY: '2', // 缴费
  WORK_ORDER: '1' // 工单
}

/**
 * 消息列表相关
 * @param {*} pageSize
 * @returns
 */
export const useMessage = (init = false, pageSize = 10) => {
  const router = useRouter()
  const state = reactive({
    pageNo: 1,
    pageSize,
    list: []
  })
  const initMessageList = async params => {
    try {
      const result = await getMessageList({ pageNo: state.pageNo, pageSize: state.pageSize, ...params })
      state.list = result.data?.list || []
      state.count = result.data?.count || 0
    } catch (error) {
      console.log('initMessageList error', error)
    }
  }
  const itemClick = message => {
    const { businessEnum } = message
    if (BUSINESS_ENUM.SYS_NOTIFY === businessEnum.id) {
      router.push({ name: 'notifyDetail', params: { id: message.id } })
    } else {
      // TODO 其他跳转方式
    }
  }
  if (init) {
    initMessageList()
  }
  return {
    state,
    initMessageList,
    itemClick
  }
}

/**
 * 公告列表
 * @param {*} init
 * @param {*} pageSize
 * @returns
 */
export const useBulletinList = (init = false, pageSize = 10, options) => {
  const router = useRouter()
  const state = reactive({
    pageNo: 1,
    pageSize,
    list: [],
    count: 0,
    loadingState: false
  })
  const initBulletinList = async () => {
    state.loadingState = true
    try {
      const result = await getBulletinList({ pageNo: state.pageNo, pageSize: state.pageSize, ...options })
      state.loadingState = false
      state.list = result.data?.list || []
      state.count = result.data?.count || 0
    } catch (error) {
      state.loadingState = false
      console.log('initBulletinList error', error)
    }
  }
  const itemClick = item => {
    router.push({ name: 'notifyDetail', params: { id: item.id } })
  }
  // 标记为已读
  const readBulletin = async id => {
    try {
      const result = await readMessageById(id)
      if (result) {
        initBulletinList()
      }
    } catch (error) {
      console.log('readBulletin err', error)
    }
  }
  if (init) {
    initBulletinList()
  }
  return {
    state,
    initBulletinList,
    itemClick,
    readBulletin
  }
}
