<template>
  <div class="notify-wrap">
    <div class="title">系统通知</div>
    <div class="action-wrap">
      <div class="action-types">
        <div
          v-for="(value, key) in MESSAGE_TYPE"
          :class="{ 'btn-type': true, 'btn-type-selected': key === state.type, 'btn-type-hover': key !== state.type }"
          :key="key"
          @click.stop="onChangeTypeClick(key)"
        >
          {{ value }}
        </div>
      </div>
      <div class="action-read"><span v-if="true" @click="onReadAllClick">全部标记为已读</span></div>
    </div>
    <div class="table-wrapper">
      <el-table
        cell-class-name="custom-table-cell logs-table-cell"
        header-row-class-name="custom-table-header logs-table-header"
        :data="messageState.list"
        @row-click="onCurrentChange"
        ref="tableRef"
        :empty-text="`暂无数据`"
        style="width: 100%"
        v-loading="messageState.loadingState"
      >
        <el-table-column align="left" show-overflow-tooltip prop="message" label="标题">
          <template #default="scope">
            <span v-if="!scope.row.read" class="unread"></span>
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="templateEnum" label="类型" width="180">
          <template #default="scope">
            <span>{{ scope.row?.templateEnum?.desc }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="createTime"
          label="发布时间"
          width="180"
        ></el-table-column>
      </el-table>
    </div>
    <pagination-block
      v-if="messageState.list?.length"
      @page-change="handleCurrentChange"
      v-model="messageState.pageNo"
      :page-size="messageState.pageSize"
      @size-change="handleSizeChange"
      :total="messageState.count"
    ></pagination-block>
  </div>
</template>
<script>
import { reactive, onActivated } from 'vue'
import { useBulletinList } from '@/hooks/message'
import { MESSAGE_TYPE } from '@/common/const'
import { readMessageByAll } from '@/apis'
import PaginationBlock from '@/components/pagination'
export default {
  name: 'notify',
  components: { PaginationBlock },
  setup() {
    const state = reactive({
      type: Object.keys(MESSAGE_TYPE)?.[0],
      jumperPage: '',
      currentParams: {}
    })
    const { state: messageState, initBulletinList: initMessageList, itemClick } = useBulletinList(false)
    const onChangeTypeClick = type => {
      state.type = type
      messageState.pageNo = 1
      switch (+type) {
        case 1: // 全部消息
          state.currentParams = {}
          break
        case 2: // 系统通知
          state.currentParams = {
            businessEnum: '4'
          }
          break
        case 3: // 待办工单
          state.currentParams = {
            successStatus: false,
            businessEnum: '1'
          }
          break
        case 4: // 代办申请
          break
      }
      initMessageList(state.currentParams)
    }
    const handleCurrentChange = page => {
      messageState.pageNo = page
      initMessageList(state.currentParams)
    }
    const handleSizeChange = size => {
      messageState.pageSize = size
      messageState.pageNo = 1
      initMessageList(state.currentParams)
    }
    const jumperClick = () => {
      if (!state.jumperPage) return false
      messageState.pageNo = state.jumperPage - 0 || 1
      initMessageList(state.currentParams)
    }
    onActivated(() => {
      initMessageList(state.currentParams)
    })
    const onReadAllClick = async () => {
      try {
        await readMessageByAll()
        initMessageList(state.currentParams)
      } catch (error) {
        console.log('onReadAllClick', error)
      }
    }
    // 当前行改变的事件
    const onCurrentChange = currentRow => {
      itemClick(currentRow)
    }
    return {
      MESSAGE_TYPE,
      state,
      onChangeTypeClick,
      messageState,
      handleCurrentChange,
      handleSizeChange,
      jumperClick,
      onReadAllClick,
      onCurrentChange
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
